import React from "react";
import Button from "./button";
import GlobalContext from "../../GlobalContext";

class Content extends React.Component {
  constructor(props, context) {
    super(props);
  }

  render() {
    const isOpen = this.context.showMoreState[this.props.id];

    let containerClass = "show-more-content ";

    if (isOpen) {
      containerClass += "show-more-content--is-open";
    }

    return (
      <div className={containerClass}>
        {isOpen && (
          <div>
            <div className="show-more-content__area">{this.props.children}</div>

            {isOpen && !this.props.hideOnOpen && (
              <div className="show-more-content__button">
                <Button
                  type="ShowMore"
                  id={this.props.id}
                  isOpen={isOpen}
                  showText={this.props.showText}
                  hideText={this.props.hideText}
                  textCenter={this.props.textCenter}
                  textWhite={this.props.textWhite}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Content.contextType = GlobalContext;

export default Content;
