import React from "react";
import { ReactComponent as OpenIcon } from "../img/icons/open-circle.svg";
import { ReactComponent as CloseIcon } from "../img/icons/close-circle.svg";
import { ReactComponent as ArrowRightCircle } from "../img/icons/arrow-right-circle.svg";

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.isOpen,
      buttonClass: "button",
      buttonClasses: "button",
    };

    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    if (this.props.onClick) {
      this.props.onClick();
    } else if (this.props.linkTo) {
    }
  }

  render() {
    let classes = "button";
    let icon = "";

    if (this.props.type === "ShowMore") {
      if (this.props.isOpen) {
        classes += ` ${this.state.buttonClass}--is-open`;
        icon = <CloseIcon />;
      } else {
        icon = <OpenIcon />;
      }
    } else if (this.props.type === "link") {
      icon = <ArrowRightCircle />;
    }

    if (this.props.textCenter) {
      classes += ` ${this.state.buttonClass}--center`;
    }
    if (this.props.textWhite) {
      classes += ` ${this.state.buttonClass}--white`;
    }
    if (this.props.textRed) {
      classes += ` ${this.state.buttonClass}--red`;
    }
    if (this.props.arrowRight) {
      classes += ` ${this.state.buttonClass}--arrow-right`;
    }
    if (this.props.arrowAlignTop) {
      classes += ` ${this.state.buttonClass}--arrow-align-top`;
    }
    if (this.props.block) {
      classes += ` ${this.state.buttonClass}--block`;
    }
    if (this.props.className) {
      classes += ` ${this.props.className}`;
    }

    if (this.props.href) {
      return (
        <a className="button-link" href={this.props.href}>
          <div className={classes}>
            <div className={this.state.buttonClass + "__icon"}>{icon}</div>

            <div className={this.state.buttonClass + "__text"}>
              {this.props.label}
            </div>
          </div>
        </a>
      );
    } else {
      return (
        <button
          disabled={this.props.disabled}
          className={classes}
          onClick={this.clickHandler}
        >
          <div className={this.state.buttonClass + "__icon"}>{icon}</div>

          <div className={this.state.buttonClass + "__text"}>
            {this.props.label}
          </div>
        </button>
      );
    }
  }
}

export default Button;
