import React, { useState } from "react";
import "../css/Page.scss";
import "../css/Home.scss";

import Wrapper from "../components/Wrapper.js";
import Search from "../components/Search.js";
import Logo from "../img/logo.svg";
import { Link } from "react-router-dom";
import ButtonOrder from "../components/ButtonOrder.js";
import Button from "../components/Button";
import TCaption from "../components/TCaption.js";
import BannerCatalog from "../components/BannerCatalog.js";

const TestDriveV5 = React.lazy(() => import("../components/TestDriveV5.js"));
const MainMenu = React.lazy(() => import("../MainMenu.js"));
const BottomMenu = React.lazy(() => import("../BottomMenu.js"));
const PersonalDiscount = React.lazy(() =>
  import("../components/PersonalDiscount")
);
const Footer = React.lazy(() => import("../Footer.js"));
const CatalogSections = React.lazy(() =>
  import("../components/CatalogSections.js")
);
const VideoReviews = React.lazy(() => import("../components/VideoReviews.js"));
const Services = React.lazy(() => import("../components/Services.js"));
const WarrantyHome = React.lazy(() => import("../components/WarrantyHome.js"));
const AboutUs = React.lazy(() => import("../components/AboutUs.js"));

const Images = {
  Mood: require("../img/categories/chairs/mood.png"),
  MoodCover: require("../img/categories/chairs/mood-cover.png"),
  Top: require("../img/home/top.png"),
  Skyliner2: require("../img/home/skyliner2.png"),
  Alpha3: require("../img/home/alpha3.png"),
  OldPrices: require("../img/home/old_prices.png"),
  AuraSale: require("../img/home/aura_sale.png"),
  Alpha3Top: require("../img/home/alpha3top.png"),
  //ActionTop: require("../img/pages/actions-top.png"),
  //SpringSale: require("../img/spring_sale.png")
  //WinterSale: require("../img/winter_sale.png")
};

function FormatPrice(price) {
  var price = price.toString().replace(" ", "");
  const priceLength = price.length;
  const blocksCount = Math.floor(price.length / 3);
  const firstBlockStart = priceLength - blocksCount * 3;

  let outPrice = price.slice(0, firstBlockStart);
  for (let i = 0; i < blocksCount; i++) {
    outPrice += ` ${price.slice(
      firstBlockStart + 3 * i,
      firstBlockStart + 3 * (i + 1)
    )}`;
  }
  return <span style={{ whiteSpace: "nowrap" }}>{outPrice}</span>;
}

function Page(props) {
  const [showMore, setShowMore] = useState({});

  /* Меню страницы */
  const menuItems = [];

  const handleShowContent = (label) => {
    const isOpen = showMore[label] ? showMore[label] : false;

    setShowMore({
      ...showMore,
      [label]: !isOpen,
    });
  };
  return (
    <div className="pageContainer home-page">
      <div id="search">
        <React.Suspense fallback={<></>}>
          <MainMenu
            items={menuItems}
            type="fixed-button"
            pageName="Главная"
            showFastMenu={false}
          />
        </React.Suspense>
        <header>
          <Link to="/" className="logo">
            <img width="156" height="36" src={Logo} alt="Casada Russia logo" />
          </Link>
        </header>
      </div>

      <BannerCatalog section="home" />

      {/*<RisingPrices
        height="847px"
        bgSize="auto 753px"
        logo
        spacerTop="105px"
        shadowTop="linear-gradient(180deg, #FFFFFF 41.67%, rgba(255, 255, 255, 0) 264px)"
        buttonMargin="220px"
      />*/}

      {/*<Wrapper
        bid="sales"
        backgroundColor="#FFFFFF"
        background={{
          inner: {
            image: Images.Alpha3Top,
            position: "center bottom",
            size: "auto 750px",
          },
        }}
        minHeight="750px"
        lazyLoad={false}
        href="/chairs/alphasonic3/"
      >
        <div>
          <header>
            <Link to="/" className="logo">
              <img src={Logo} alt="Casada Russia logo" />
            </Link>
          </header>

          <TCaption.Wrapper center>
            <TCaption.Spacer height="60px" />
            <TCaption.Title><div style={{width: "260px", margin: "auto", textAlign: "center"}}>Alphasonic 3</div></TCaption.Title>
            <TCaption.Sub><div style={{width: "260px", margin: "auto", textAlign: "center"}}>новинка 2022</div></TCaption.Sub>
            <TCaption.Spacer height="10px" />
            <TCaption.SmallText>
              Рассрочка от {FormatPrice(Math.ceil(parseInt(props.products[146200].colors[0].newPrice.replace(" ", "")) / 120) * 10)}&nbsp;₽/мес
              <br />
              Trade-in от {FormatPrice(Math.ceil(parseInt(props.products[146200].colors[0].newPrice.replace(" ", "")) * 0.07) * 10)}&nbsp;₽
            </TCaption.SmallText>
            <TCaption.Spacer height="10px" />
          </TCaption.Wrapper>
          
          <Link to="chairs/alphasonic3/">
            <Button label="Подробнее" arrowRight textCenter type="link" />
          </Link>

          <div className="bottom-line text-center">
            <ButtonOrder productId="146200" products={props.products} />
          </div>
        </div>
      </Wrapper>*/}

      {/*<Wrapper
        bid="skyliner2"
        minHeight="929px"
        background={{
          inner: {
            image: Images.Skyliner2,
            position: "bottom center",
            size: "auto 644px",
          },
        }}
        href="/chairs/skyliner2/grey/"
      >
        <div className="top-line">
          <header>
            <Link to="/" className="logo">
              <img src={Logo} alt="Casada Russia logo" />
            </Link>
          </header>
          <TCaption.Wrapper center>
            <TCaption.Spacer height="98px" />
            <TCaption.Title>Skyliner 2</TCaption.Title>
            <TCaption.Sub>
              премиум-класс <br />
              от&nbsp;
              <MinPrice productId="112446" />
              &nbsp;₽
            </TCaption.Sub>
            <TCaption.Spacer height="16px" />
            <TCaption.SmallText>
              Рассрочка от&nbsp;
              {FormatPrice(
                Math.ceil(props.products[112446]["price_base"] / 120) * 10
              )}
              &nbsp;₽/мес
              <br />
              или Trade-in от&nbsp;
              {FormatPrice(
                Math.ceil(props.products[112446]["price_base"] * 0.07) * 10
              )}
              &nbsp;₽
            </TCaption.SmallText>
          </TCaption.Wrapper>
        </div>

        <div className="center-line">
          <Link to="chairs/skyliner2/grey/">
            <Button label="Подробнее" arrowRight type="link" />
          </Link>*/}

      {/*<img className="img-fluid" src={Images.Skyliner2} />*/}
      {/*</div>

        <div className="bottom-line text-center">
          <ButtonOrder feedPosition={1} product={props.products[112446]} />
        </div>
      </Wrapper>*/}

      {/*<Wrapper*/}
      {/*  bid="complects"*/}
      {/*  backgroundColor="#F4F6F6"*/}
      {/*  background={{*/}
      {/*    inner: {*/}
      {/*      image: Images.ActionTop,*/}
      {/*      position: "center bottom",*/}
      {/*      size: "100% auto",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  minHeight="800px"*/}
      {/*  lazyLoad={false}*/}
      {/*>*/}
      {/*  <div*/}
      {/*    style={{*/}
      {/*      background:*/}
      {/*        "linear-gradient(180deg, #FFFFFF 42.81%, rgba(255, 255, 255, 0) 100%)",*/}
      {/*      minHeight: "400px"*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <header>*/}
      {/*      <Link to="/" className="logo">*/}
      {/*        <img src={Logo} alt="Casada Russia logo" />*/}
      {/*      </Link>*/}
      {/*    </header>*/}
      {/*    <TCaption.Wrapper center>*/}
      {/*      <TCaption.Spacer height="100px"/>*/}
      {/*      <TCaption.Title>Щедрый июнь</TCaption.Title>*/}
      {/*      <TCaption.Sub>подарки при покупке кресел<br/>и массажёров</TCaption.Sub>*/}
      {/*      <TCaption.Spacer height="40px"/>*/}
      {/*      <Link to={{*/}
      {/*        pathname: "/actions/products/",*/}
      {/*        query: {*/}
      {/*          scrollTo: "#complect-card-0"*/}
      {/*        }*/}
      {/*      }}>*/}
      {/*        <Button*/}
      {/*          type="link"*/}
      {/*          label="Подробнее"*/}
      {/*          textCenter*/}
      {/*          arrowRight*/}
      {/*        />*/}
      {/*      </Link>*/}
      {/*    </TCaption.Wrapper>*/}
      {/*  </div>*/}
      {/*</Wrapper>*/}

      {/*<Wrapper*/}
      {/*  bid="top"*/}
      {/*  background={{*/}
      {/*    inner: {*/}
      {/*      image: Images.Top,*/}
      {/*      position: "bottom center",*/}
      {/*      size: "100% auto",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  backgroundColor="#FFFFFF"*/}
      {/*  minHeight="753px"*/}
      {/*  lazyLoad={true}*/}
      {/*  href="/actions/"*/}
      {/*>*/}
      {/*  <TCaption.Wrapper center>*/}
      {/*    <TCaption.Spacer height="28px" />*/}
      {/*    <TCaption.Title>Вместе выгодней!</TCaption.Title>*/}
      {/*    <TCaption.Sub>*/}
      {/*      скидка до&nbsp;8&nbsp;900&nbsp;₽ <br />*/}
      {/*      на&nbsp;комплекты массажеров*/}
      {/*    </TCaption.Sub>*/}
      {/*    <TCaption.Spacer height="30px" />*/}
      {/*    <Link to="/actions/complects/">*/}
      {/*      <Button label="Подробнее" arrowRight textCenter type="link" />*/}
      {/*    </Link>*/}
      {/*  </TCaption.Wrapper>*/}
      {/*</Wrapper>*/}

      {/* Список разделов каталога с картинками */}
      <Wrapper bid="catalog" lazyLoad>
        <React.Suspense fallback={<></>}>
          <CatalogSections />
        </React.Suspense>
      </Wrapper>

      <Wrapper
        bid="alpha3"
        minHeight="768px"
        lazyLoad
        href="/chairs/alphasonic3/"
      >
        <TCaption.Wrapper center>
          <TCaption.Spacer height="60px" />
          <div className="new-label">Новинка</div>
          <TCaption.Title>Alphasonic 3</TCaption.Title>
          <TCaption.Sub>
            массажное кресло нового <br />
            поколения с&nbsp;3D массажем
          </TCaption.Sub>
          <TCaption.Spacer height="30px" />
          <Link to="/chairs/alphasonic3/">
            <Button label="Подробнее" arrowRight textCenter type="link" />
          </Link>
          <TCaption.Spacer height="20px" />
          <div className="image-wrapper">
            <img src={Images.Alpha3} alt="Alphasonic 3" />
          </div>
        </TCaption.Wrapper>
        <div className="bottom-line">
          <ButtonOrder productId="146200" products={props.products} />
        </div>
      </Wrapper>

      <Wrapper
        bid="popular-products"
        minHeight="650px"
        backgroundColor="#f4f6f5"
        lazyLoad
      >
        <TCaption.Wrapper left>
          <TCaption.Spacer height="50px" />
          <TCaption.AccentTitle>Массажеры Casada</TCaption.AccentTitle>
          <TCaption.Sub>популярные товары</TCaption.Sub>
          <TCaption.Spacer height="20px" />
          <TCaption.Text>
            Эти товары чаще всего просматривают и покупают в этом месяце
          </TCaption.Text>
        </TCaption.Wrapper>
        <Search
          noLogo
          category={["chairs", "massagers"]}
          showPopular
          hideFilterButton
          products={props.products}
        />
      </Wrapper>

      <Wrapper bid="tradein" lazyLoad>
        <React.Suspense fallback={<></>}>
          <img src={Images.Mood} alt="" className="img-fluid" />

          <TCaption.Wrapper center>
            <TCaption.Spacer height="20px" />
            <TCaption.Title>Выкуп кресел</TCaption.Title>
            <TCaption.Sub>Узнайте цену за 30 секунд</TCaption.Sub>
            <TCaption.Spacer height="30px" />
            <TCaption.Text>
              Сдайте старое массажное кресло любого производителя
              и&nbsp;получите скидку при покупке в&nbsp;Casada по&nbsp;программе
              Trade-in
            </TCaption.Text>
            <TCaption.Spacer height="50px" />
            <Link to="/tradein/">
              <Button
                label="Рассчитать скидку"
                arrowRight
                textCenter
                type="link"
              />
            </Link>
            <TCaption.Spacer height="25px" />
          </TCaption.Wrapper>
        </React.Suspense>
      </Wrapper>

      <Wrapper bid="testdrive" lazyLoad>
        <React.Suspense fallback={<></>}>
          <TestDriveV5
            customTitle={
              <span style={{ fontWeight: "600" }}>кресел Casada</span>
            }
            type="product"
            showMore={false}
            withProductList
            categoryProductList="all"
          />
        </React.Suspense>
      </Wrapper>

      <Wrapper bid="about" lazyLoad>
        <React.Suspense fallback={<></>}>
          <AboutUs />
        </React.Suspense>
      </Wrapper>

      <Wrapper
        bid="video-reviews"
        backgroundColor="#F4F6F6"
        minHeight="800px"
        lazyLoad
      >
        <React.Suspense fallback={<></>}>
          <VideoReviews />
        </React.Suspense>
      </Wrapper>

      <Wrapper bid="warranty" lazyLoad backgroundColor="#f4f6f6">
        <React.Suspense fallback={<></>}>
          <WarrantyHome />
        </React.Suspense>
      </Wrapper>

      <Wrapper bid="services" lazyLoad backgroundColor="#f4f6f6">
        <React.Suspense fallback={<></>}>
          <Services />
        </React.Suspense>
      </Wrapper>

      <React.Suspense fallback={<></>}>
        <PersonalDiscount />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <Footer />
      </React.Suspense>

      <React.Suspense fallback={<></>}>
        <BottomMenu
          product="Skyliner 2"
          productId="112446"
          description="Массажное кресло премиум-класса"
          price="619 000 ₽"
          noBuy
        />
      </React.Suspense>
    </div>
  );
}

export default Page;
